<div class="header">

  <div class="header-last-synced" style="width: 25%">
      last update received on {{'02.04.2025 @ 22:58'}}
  </div>

  <h1>OBD2 Log Entries</h1>

  <div style="width: 25%; display: flex; justify-content: end">
    <button
      id="login"
      *ngIf="!userLoggedIn()"
      (click)="onLogin()">
      Login
    </button>
  </div>

</div>

<div *ngIf="loading" class="loading-indicator">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="dashboard-container" *ngIf="!loading">

  <div class="card">
    <div class="car">
      <img class="car" src="assets/pictures/car.png" alt="car">
      <img class="tyre tyre1" src="assets/pictures/tyre.png" alt="tyre1">
      <img class="tyre tyre2" src="assets/pictures/tyre.png" alt="tyre2">
    </div>

    <table border="1" cellspacing="0" cellpadding="6" style="margin-top: 2rem;">
      <thead>
      <tr>
        <th>Datenpunkt</th>
        <th>Wert</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Kilometerstand</td>
        <td>? km</td>
      </tr>
      <tr>
        <td>Tankfüllstand</td>
        <td>73 %</td>
      </tr>
      <tr>
        <td>Batteriespannung</td>
        <td>12.5 V (gemessen mit ESP)</td>
      </tr>
      <tr>
        <td>Reifendruck</td>
        <td>
          <ul style="margin: 0; padding-left: 20px;">
            <li>? bar</li>
            <li>? bar</li>
            <li>? bar</li>
            <li>? bar</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Aktuelle Probleme</td>
        <td>
          <ul style="margin: 0; padding-left: 20px;">
            <li>?</li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

  <div class="card">
    <app-telemetry-map [journey]="journeyData"></app-telemetry-map>
  </div>

  <div class="card" style="max-height: 50rem; overflow: hidden">
    <h3>Raw Entries</h3>
    <div *ngFor="let e of entries">
      <!--<i class="date">{{ e.serverDate | date: 'yyyy.MM.dd HH:mm:ss' }}</i>-->
      {{ e.content }}
    </div>
  </div>

  <!--
  <div class="card">
    <h3>New logs can be posted like the following</h3>
    curl -X POST {{ globals.backendUri }}/logs -H "Content-Type: application/json" -H "Authorization: TOKEN" -d '&#123;"content": "This is a test log entry", "date": "2025-03-05T14:48:00.000Z"}'
  </div>
-->

  <div class="charts">

  <div class="card">
    <app-chart
      [title]="'Speed (km/h)'"
      [data]="speedValues"
      [labels]="timestamps"
      [color]="'#f40000'">
    </app-chart>
  </div>

  <div class="card">
    <app-chart
      [title]="'Engine RPM'"
      [data]="rpmValues"
      [labels]="timestamps"
      [color]="'#0004f4'">
    </app-chart>
  </div>

  <div class="card">
    <app-chart
      [title]="'Engine Load (%)'"
      [data]="loadValues"
      [labels]="timestamps"
      [color]="'#07910c'">
    </app-chart>
  </div>
  </div>

</div>
