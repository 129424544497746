import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit{

  @Input() title: string = '';
  @Input() data: number[] = [];
  @Input() labels: string[] = [];
  @Input() color: string = 'blue';

  data2: any;

  options: any;

  ngOnInit() {
    this.data2 = {
      labels: this.labels,
      datasets: [
        {
          label: this.title,
          data: this.data,
          fill: false,
          borderColor: this.color
        }
      ]
    };

    this.options = {
      indexAxis: 'x',
      plugins: {
        legend: {
          display: true,
          position: 'bottom', // Positioning the legend on the right
        }
      },
      responsive: true,
      interaction: {
        intersect: false,
      },
      elements: {
        line: {
          borderWidth: 3 // Adjusts the line thickness (1 is thinner, increase for thicker lines)
        },
        point: {
          radius: 2 // Removes the data point markers
        }
      }
    };
  }
}

