import { AfterViewInit, Component, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-telemetry-map',
  templateUrl: './telemetry-map.component.html',
  styleUrls: ['./telemetry-map.component.scss']
})
export class TelemetryMapComponent implements AfterViewInit {

  @Input() journey: { latitude: number, longitude: number }[] = [];

  private map!: L.Map;

  constructor() {}

  ngAfterViewInit(): void {
    this.initMap();
    this.renderJourney();
  }

  private initMap(): void {
    // normal
    const baseMapURL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    // satellite
    // const baseMapURL = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';


    this.map = L.map('map', {
      zoomControl: true
    });

    L.tileLayer(baseMapURL, {
      attribution: '© OpenStreetMap contributors',
      maxZoom: 18
    }).addTo(this.map);
  }

  private renderJourney(): void {
    if (!this.journey || this.journey.length === 0) return;

    const points: L.LatLngTuple[] = this.journey.map(p => [p.latitude, p.longitude]);

    // Draw polyline for the journey
    const path = L.polyline(points, { color: 'blue' }).addTo(this.map);

    // Add start and end markers
    L.marker(points[0], {
      icon: L.icon({
        iconUrl: '/leaflet/marker-icon.png',
        shadowUrl: '/leaflet/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [13, 41]
      })
    }).addTo(this.map).bindPopup("Start");

    if (points.length > 1) {
      L.marker(points[points.length - 1], {
        icon: L.icon({
          iconUrl: '/leaflet/marker-icon-red.png', // customize or reuse default
          shadowUrl: '/leaflet/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [13, 41]
        })
      }).addTo(this.map).bindPopup("End");
    }

    // Fit the map view to the journey bounds
    this.map.fitBounds(path.getBounds(), { padding: [10, 10] });
  }
}
