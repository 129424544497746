import {Component} from '@angular/core';
import { LogEntry } from 'src/app/dtos/logs/LogEntry';
import { Telemetry } from 'src/app/dtos/telemetry/Telemetry';
import { Globals } from 'src/app/global/globals';
import { TelemetryService } from 'src/app/services/rest/telemetry.service';
import {InputButtonIconType} from "../core/button/button.utils";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/rest/authentication.service";
import {LogService} from "src/app/services/rest/log.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent {
  InputButtonIconType = InputButtonIconType;

  loading = true;
  entries: LogEntry[] = [];
  telemetry: Telemetry[] = [];

  speedValues: number[] = [];
  rpmValues: number[] = [];
  loadValues: number[] = [];
  timestamps: string[] = [];

  journeyData: {latitude: number, longitude: number}[] = [];

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private logService: LogService,
              private telemetryService: TelemetryService,
              public globals: Globals,
              private datePipe: DatePipe) {
    this.getDashboard();
  }


  getDashboard() {
    this.logService.getAll().subscribe(
      resp => {
        this.entries = resp;
        this.loading = false;
      }
    )
    this.telemetryService.getAll().subscribe(
      resp => {
        this.telemetry = resp;
        this.journeyData = this.telemetry.map(t => {
          return {latitude: t.latitude, longitude: t.longitude}
        });
        this.loadValues = this.telemetry.map(t => {
          return t.engineLoad;
        });
        this.rpmValues = this.telemetry.map(t => {
          return t.rpm;
        });
        this.speedValues = this.telemetry.map(t => {
          return t.speed;
        });
        this.timestamps = this.telemetry.map(t => {
          return t.utcTime;
        });
      }
    )
  }

  onLogin() {
    this.router.navigate(['login']);
  }

  userLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

}
