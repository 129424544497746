import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  static JAVA_MAX_INT = 2147483647;
  readonly pageMinDelay: number = 500;

  readonly backendUri: string = this.findBackendUrl();

  private findBackendUrl(): string {
    if (true || window.location.port === '4200') { // local `ng serve`, backend at localhost:8080
      return 'https://www.project-ocean.at/api/v1';
    } else {
      // assume deployed somewhere and backend is available at same host/port as frontend
      return window.location.protocol + '//' + window.location.host + window.location.pathname + 'api/v1';
    }
  }

}


