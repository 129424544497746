import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {SharedModule} from "../../shared/shared.module";
import {ChartModule} from "primeng/chart";
import {DatePipe} from "@angular/common";
import { TelemetryMapComponent } from './telemetry-map/telemetry-map.component';
import { ChartComponent } from './chart/chart.component';

@NgModule({
  declarations: [DashboardComponent, TelemetryMapComponent, ChartComponent],
  imports: [
    SharedModule,
    ChartModule,
  ],
  exports: [DashboardComponent],
  providers: [DatePipe]
})
export class DashboardModule {

}
