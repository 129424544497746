import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, switchMap, map } from 'rxjs/operators';
import { LogEntry } from 'src/app/dtos/logs/LogEntry';
import { Globals } from '../../global/globals';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private baseUri: string = this.globals.backendUri + '/logs';

  constructor(private httpClient: HttpClient, private globals: Globals) {}

  /**
   * Get all log entries. Applies 1.5s delay only when `afterId` is NOT provided.
   * Converts date fields to Date objects.
   */
  getAll(afterId?: number): Observable<LogEntry[]> {
    let params = new HttpParams();
    if (afterId !== undefined) {
      params = params.set('afterId', afterId.toString());
    }

    const request$ = this.httpClient.get<LogEntry[]>(this.baseUri, { params }).pipe(
      map(logs => logs.map(log => ({
        ...log,
        date: log.date ? new Date(log.date) : null,
        serverDate: log.serverDate ? new Date(log.serverDate) : null
      })))
    );

    if (afterId !== undefined) {
      return request$;
    }

    return of(null).pipe(
      delay(1500),
      switchMap(() => request$)
    );
  }

  /**
   * Create a new log entry
   */
  create(dto: { content: string }): Observable<LogEntry> {
    return this.httpClient.post<LogEntry>(this.baseUri, dto).pipe(
      map(log => ({
        ...log,
        date: log.date ? new Date(log.date) : null,
        serverDate: log.serverDate ? new Date(log.serverDate) : null
      }))
    );
  }

  /**
   * Delete all log entries
   */
  deleteAll(): Observable<void> {
    return this.httpClient.delete<void>(this.baseUri);
  }

}
