import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Telemetry } from 'src/app/dtos/telemetry/Telemetry';
import { Globals } from 'src/app/global/globals';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  private baseUrl = this.globals.backendUri + '/telemetry';

  constructor(private http: HttpClient, private globals: Globals) {}

  getAll(): Observable<Telemetry[]> {
    return this.http.get<Telemetry[]>(this.baseUrl);
  }

  post(telemetry: Telemetry): Observable<any> {
    return this.http.post(this.baseUrl, telemetry);
  }

}
